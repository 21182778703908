<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Товары и торговые предложения</h5>
      </div>
    </div>
    <div class="col-12 xl:col-3">
      <div class="card">
        <h5>Продукция</h5>
        <Toolbar class="mb-4">
          <template #start>
            <Button label="Add Product" />
          </template>
        </Toolbar>
        <Listbox v-model="product" :options="products" optionLabel="name" filter="true" />
      </div>
    </div>
    <div class="col-12 xl:col-9">
      <div class="card" v-if="product">
        <Toolbar class="mb-4">
          <template #start>
            <h5>Управление: {{ product.name }}</h5>
          </template>
          <template #end>
            <Button label="New Offer" icon="pi pi-plus" class="p-button-success mr-2" @click="addOffer" />
            <Button
                label="Delete Product"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteProduct" />
          </template>
        </Toolbar>
        <DataTable
            :value="product.offers"
            dataKey="id"
            editMode="cell"
            v-model:editingRows="editingRows"
            @row-edit-save="onRowEditSave"
            responsiveLayout="scroll"
        >
          <Column field="color" header="Цвет" :sortable="true" style="min-width:6rem">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" autofocus />
            </template>
          </Column>
          <Column field="price" header="Цена" :sortable="true" style="min-width:6rem">
            <template #body="slotProps">
              {{formatCurrency(slotProps.data.price)}} Р.
            </template>
          </Column>
          <Column field="remain" header="Остатки" :sortable="true" style="min-width:6rem"></Column>
          <Column field="size" header="Размер" :sortable="true" style="min-width:6rem"></Column>
          <Column field="width" header="Вес" :sortable="true" style="min-width:6rem"></Column>
          <Column field="isOnlyOnOrder" header="Под заказ" :sortable="true" style="min-width:6rem"></Column>
          <Column :exportable="false" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editOffer(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteOffer(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KindressaManager",
  data () {
    return {
      products: [],
      product: null,
      editingRows: []
    }
  },
  async mounted() {
    const products = await this.axios.get('/api/products')
    console.log(products.data)
    this.products = products.data['hydra:member']
  },
  methods: {
    // TODO https://primevue.org/datatable/edit
    formatCurrency(value) {
      // TODO Почему не работает? И может сразу делать редактирование?
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      return;
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      console.log(newData)
      console.log(index)
      // this.products2[index] = newData;
    },
  }
}
</script>

<style scoped>

</style>
